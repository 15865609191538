<template>
  <Layout>
    <div class="container">
      <b-row class="spaceWizzard">
        <b-col
          cols="12"
          md="3"
          class="pt-5"
          data-anima="top"
          :class="{ hide: editedEmail === true }"
        >
          <div class="navigate">
            <div class="title">
              {{ nameCourse }}
              <button
                id="viewModuleLesson"
                class="viewModuleLesson"
                @click="viewCourse()"
              >
                Ver Curso
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.375 8.75L12.5 5.625L9.375 2.5"
                    stroke="#C4C4C4"
                    stroke-width="1.8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.5 12.5V8.125C2.5 7.46194 2.76339 6.82606 3.23223 6.35725C3.70107 5.88839 4.33696 5.625 5 5.625H12.5"
                    stroke="#C4C4C4"
                    stroke-width="1.8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div class="lineDiv"></div>
            <router-link :to="'/config_curso/' + cursoAtual" class="removeLink">
              <div class="spaceNav">
                <div class="text">
                  <svg
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V11C0 11.7956 0.316071 12.5587 0.87868 13.1213C1.44129 13.6839 2.20435 14 3 14H9V16H5C4.73478 16 4.48043 16.1054 4.29289 16.2929C4.10536 16.4804 4 16.7348 4 17C4 17.2652 4.10536 17.5196 4.29289 17.7071C4.48043 17.8946 4.73478 18 5 18H15C15.2652 18 15.5196 17.8946 15.7071 17.7071C15.8946 17.5196 16 17.2652 16 17C16 16.7348 15.8946 16.4804 15.7071 16.2929C15.5196 16.1054 15.2652 16 15 16H11V14H17C17.7956 14 18.5587 13.6839 19.1213 13.1213C19.6839 12.5587 20 11.7956 20 11V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM18 11C18 11.2652 17.8946 11.5196 17.7071 11.7071C17.5196 11.8946 17.2652 12 17 12H3C2.73478 12 2.48043 11.8946 2.29289 11.7071C2.10536 11.5196 2 11.2652 2 11V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V11Z"
                      fill="#333333"
                    />
                  </svg>
                  Dados do Curso
                </div>
                <IconSax name="arrow-right-3" size="1rem"  />
              </div>
            </router-link>
            <div class="lineDiv"></div>
            <router-link
              :to="'/config_curso/' + cursoAtual + '/module'"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text">
                  <svg
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.47 16.82L20.47 12.96L17.32 1.37003C17.2519 1.11437 17.0853 0.896104 16.8567 0.76303C16.628 0.629955 16.3559 0.592918 16.1 0.66003L12.23 1.66003C12.1376 1.55767 12.0251 1.47552 11.8995 1.41873C11.7739 1.36194 11.6379 1.33174 11.5 1.33003H1.5C1.23478 1.33003 0.98043 1.43539 0.792893 1.62292C0.605357 1.81046 0.5 2.06481 0.5 2.33003V18.33C0.5 18.5952 0.605357 18.8496 0.792893 19.0371C0.98043 19.2247 1.23478 19.33 1.5 19.33H11.5C11.7652 19.33 12.0196 19.2247 12.2071 19.0371C12.3946 18.8496 12.5 18.5952 12.5 18.33V10.33L14.7 18.55C14.7586 18.7682 14.8893 18.9601 15.0709 19.0945C15.2525 19.2288 15.4742 19.2978 15.7 19.29C15.7864 19.2999 15.8736 19.2999 15.96 19.29L20.79 18C20.9177 17.9659 21.0374 17.9068 21.1421 17.8261C21.2468 17.7454 21.3344 17.6448 21.4 17.53C21.5055 17.3088 21.5302 17.0576 21.47 16.82ZM5.47 17.37H2.47V15.37H5.47V17.37ZM5.47 13.37H2.47V7.37003H5.47V13.37ZM5.47 5.37003H2.47V3.37003H5.47V5.37003ZM10.47 17.37H7.47V15.37H10.47V17.37ZM10.47 13.37H7.47V7.37003H10.47V13.37ZM10.47 5.37003H7.47V3.37003H10.47V5.37003ZM12.72 3.63003L15.62 2.85003L16.14 4.78003L13.24 5.56003L12.72 3.63003ZM15.31 13.29L13.76 7.49003L16.66 6.71003L18.21 12.51L15.31 13.29ZM16.31 17.15L15.79 15.22L18.69 14.44L19.21 16.37L16.31 17.15Z"
                      fill="#333333"
                    />
                  </svg>
                  Módulos e Aulas
                </div>
                <IconSax name="arrow-right-3" size="1rem"  />
              </div>
            </router-link>
            <div class="lineDiv"></div>
            <router-link
              :to="'/config_curso/class/' + cursoAtual + ''"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text">
                  <svg
                    width="26"
                    height="22"
                    viewBox="0 0 26 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24 20V18C23.9993 17.1137 23.7044 16.2528 23.1614 15.5523C22.6184 14.8519 21.8581 14.3516 21 14.13M18 20V18C18 16.9391 17.5786 15.9217 16.8284 15.1716C16.0783 14.4214 15.0609 14 14 14H6C4.93913 14 3.92172 14.4214 3.17157 15.1716C2.42143 15.9217 2 16.9391 2 18V20M17 2.13C17.8604 2.3503 18.623 2.8507 19.1676 3.55231C19.7122 4.25392 20.0078 5.11683 20.0078 6.005C20.0078 6.89317 19.7122 7.75608 19.1676 8.45769C18.623 9.1593 17.8604 9.6597 17 9.88M14 6C14 8.20914 12.2091 10 10 10C7.79086 10 6 8.20914 6 6C6 3.79086 7.79086 2 10 2C12.2091 2 14 3.79086 14 6Z"
                      stroke="#333333"
                      stroke-width="2.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Turmas
                </div>
                <IconSax name="arrow-right-3" size="1rem"  />
              </div>
            </router-link>
            <div class="lineDiv"></div>
            <router-link
              :to="'/create-certificates/' + cursoAtual + ''"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.43496 22.9609H16.6119H8.43496ZM12.5234 22.9609V16.4194V22.9609ZM19.065 10.6955C19.065 8.10753 19.0609 3.81517 19.0588 2.51862C19.0588 2.30175 18.9727 2.09377 18.8193 1.94042C18.666 1.78708 18.458 1.70093 18.2411 1.70093L6.80417 1.71422C6.58775 1.71421 6.38016 1.80001 6.22688 1.95281C6.07361 2.1056 5.98716 2.31293 5.98648 2.52935C5.98648 4.09216 5.97984 9.13067 5.97984 10.6955C5.97984 13.9806 10.2216 16.4194 12.5214 16.4194C14.8211 16.4194 19.065 13.9806 19.065 10.6955ZM19.065 10.6955C21.4388 10.6955 23.1534 7.79374 23.1534 4.97169V4.154H19.065V10.6955ZM5.98188 4.154H1.89343V4.97169C1.89343 7.79374 3.60803 10.6955 5.98188 10.6955V4.154Z"
                      stroke="#333333"
                      stroke-width="1.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Certificado
                </div>
                <IconSax name="arrow-right-3" size="1rem"  />
              </div>
            </router-link>
            <!-- <div class="lineDiv"></div>
                    <router-link :to="'/config_curso/emails/'+cursoAtual+''" class="removeLink" :class="{disableLink:existParans}">
                        <div class="spaceNav">
                            <div class="text"><svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V13C0 13.7956 0.316071 14.5587 0.87868 15.1213C1.44129 15.6839 2.20435 16 3 16H17C17.7956 16 18.5587 15.6839 19.1213 15.1213C19.6839 14.5587 20 13.7956 20 13V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3L10 7.88L2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2ZM18 13C18 13.2652 17.8946 13.5196 17.7071 13.7071C17.5196 13.8946 17.2652 14 17 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V5.28L9.48 9.85C9.63202 9.93777 9.80446 9.98397 9.98 9.98397C10.1555 9.98397 10.328 9.93777 10.48 9.85L18 5.28V13Z" fill="#333333"/>
</svg>
  E-mails</div>
                            <img src="@/assets/icons/<IconSax name="arrow-right-3" size="1rem"  />.svg">
                        </div>
                    </router-link> -->
            <div class="lineDiv"></div>
            <router-link
              :to="'/config_curso/vendas/' + cursoAtual + ''"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 2L3 6V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H19C19.5304 22 20.0391 21.7893 20.4142 21.4142C20.7893 21.0391 21 20.5304 21 20V6L18 2H6Z"
                      stroke="#333333"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0609 14 12 14C10.9391 14 9.92172 13.5786 9.17157 12.8284C8.42143 12.0783 8 11.0609 8 10"
                      stroke="#333333"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3 6H21"
                      stroke="#333333"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  Vendas
                </div>
                <IconSax name="arrow-right-3" size="1rem"  />
              </div>
            </router-link>
            <div class="lineDiv"></div>

            <div class="spaceBtn">
              <toggle-button
                :sync="true"
                @change="enventSwitchStatus"
                :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
                v-model="myDataVariable"
              />
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="9"
          class="pt-5"
          data-anima="top"
          :class="{ hide: editedEmail === true }"
        >
          <div class="containerWizzard">
            <div class="title">
              Configure os E-mails
              <svg
                width="34"
                height="34"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 4H5C4.20435 4 3.44129 4.31607 2.87868 4.87868C2.31607 5.44129 2 6.20435 2 7V17C2 17.7956 2.31607 18.5587 2.87868 19.1213C3.44129 19.6839 4.20435 20 5 20H19C19.7956 20 20.5587 19.6839 21.1213 19.1213C21.6839 18.5587 22 17.7956 22 17V7C22 6.20435 21.6839 5.44129 21.1213 4.87868C20.5587 4.31607 19.7956 4 19 4ZM5 6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7L12 11.88L4 7C4 6.73478 4.10536 6.48043 4.29289 6.29289C4.48043 6.10536 4.73478 6 5 6ZM20 17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H5C4.73478 18 4.48043 17.8946 4.29289 17.7071C4.10536 17.5196 4 17.2652 4 17V9.28L11.48 13.85C11.632 13.9378 11.8045 13.984 11.98 13.984C12.1555 13.984 12.328 13.9378 12.48 13.85L20 9.28V17Z"
                  fill="#333333"
                />
              </svg>
            </div>
          </div>
          <div class="containerWizzardInputs">
            <div class="btnsGrid">
              <div class="spaceBtns">
                <button class="btnActionEmail" @click="editEmail('liberacao')">
                  E-mail de Liberação de Acesso
                </button>
              </div>
              <div class="spaceBtns">
                <button
                  class="btnActionEmail"
                  @click="editEmail('certificado')"
                >
                  E-mail de Certificado
                </button>
              </div>
            </div>
            <!-- <div class="spaceBtns">
                       <button class="btnActionEmail" @click="editEmail('bemvindo')">E-mail de Bem-vindo</button>
                   </div> -->
            <!-- <div class="spaceBtns">
                       <button class="btnActionEmail" @click="editEmail('bloqueio')">E-mail de Bloqueio de Acesso</button>
                   </div> -->
          </div>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="pt-3 mb-3"
          data-anima="top"
          :class="{ hide: editedEmail === false }"
        >
          <div class="content-email-total containerWizzardInputs">
            <div id="example" class="content-email-editor">
              <div id="bar">
                <div class="esconde-logo"></div>
                <EmailEditor
                  ref="emailEditor"
                  v-on:load="editorLoaded"
                  v-on:designUpdated="designUpdated"
                  :designTags="designTags"
                  :options="options_unlayer"
                  locale="pt-BR"
                  :translations="traducaoUnlayer"
                />
              </div>
            </div>
            <div class="spacebtnEmail animationOpacity2">
              <button @click="cancelaEmail" class="btn-cancelaremail">
                Cancelar
              </button>
              <button @click="salvarEmail" class="btn-salvaremail">
                Salvar Email
              </button>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </Layout>
</template>
<script>
import Layout from "@/layout/Layout.vue";

import Slidebar from "@/components/Slidebar.vue";
import CourseService from "@/services/resources/CourseService";
import { EmailEditor } from "../../node_modules/vue-email-editor";
import white from "../assets/unlayer/sample.json";
import traducao_unlayer from "../services/libs/traducao_unlayer.json";
import { ToggleButton } from "vue-js-toggle-button";
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();
import notify from "@/services/libs/notificacao";
const serviceCourse = CourseService.build();

export default {
  components: {
    Layout,
    Slidebar,
    ToggleButton,
    EmailEditor,
  },
  data() {
    return {
      cursoAtual: "",
      existParans: true,
      nameCourse: "",
      myDataVariable: true,
      editedEmail: false,
      editedEmailType: false,
      designTags: {
        business_name: "G Digital",
        current_user_name: "G Digital",
        link_botao: "https://google.com.br",
      },
      options_unlayer: {
        mergeTags: [
          { name: "Nome do Cliente", value: "{{nome}}" },
          { name: "Primeiro nome do Cliente", value: "{{primeiro_nome}}" },
          { name: "E-mail do Cliente", value: "{{lead}}" },
        ],
        features: {
          textEditor: {
            cleanPaste: false,
          },
        },
        designTags: {
          business_name: "",
          current_user_name: "",
          link_botao: "https://google.com.br",
        },
      },
      traducaoUnlayer: traducao_unlayer,
    };
  },
  methods: {
    viewCourse() {
      serviceCourse.read(this.cursoAtual + "/module").then((resp) => {
        //console.log("get modules", resp);
        if (resp.length === 0) {
          notify("erro", "Nenhum Módulo Configurado!");
          return false;
        }
        let routeData = this.$router.resolve("/curso/" + this.cursoAtual);
        window.open(routeData.href, "_blank");
      });
    },

    enventSwitchStatus() {
      var xxxStatus = "";
      if (this.myDataVariable === true) {
        xxxStatus = "published";
      } else {
        xxxStatus = "draft";
      }
      let data = {
        id: this.cursoAtual,
        status: xxxStatus,
      };
      //console.log(data);
      this.$root.$emit("loadOn");
      serviceCourse
        .postID({ id: this.cursoAtual, status: xxxStatus })
        .then((resp) => {
          this.$root.$emit("loadOff");
          //console.log("update course status", resp);
          this.getCourse();
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    cancelaEmail() {
      this.editedEmail = false;
    },
    salvarEmail() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        //console.log('exportHtml', data);
        var urlDesign = "";
        var urlHtml = "";
        if (this.editedEmailType === "liberacao") {
          urlDesign = "welcomeCourseEmailDesign";
          urlHtml = "welcomeCourseEmailHtml";
        } else {
          urlDesign = "certificateEmailDesign";
          urlHtml = "certificateEmailHtml";
        }

        var dataDesign = {
          id: urlDesign,
          value: JSON.stringify(data.design),
        };
        //console.log(data);
        this.$root.$emit("loadOn");
        serviceMeta
          .postID(dataDesign)
          .then((resp) => {
            //console.log("meta email design update", resp);
            this.$root.$emit("loadOff");
          })
          .catch((err) => {
            //console.log(err);
            this.$root.$emit("loadOff");
          });

        var dataHtml = {
          id: urlHtml,
          value: data.html,
        };
        //console.log(data);
        this.$root.$emit("loadOn");
        serviceMeta
          .postID(dataHtml)
          .then((resp) => {
            //console.log("meta email design update", resp);
            this.$root.$emit("loadOff");
            this.editedEmail = false;
          })
          .catch((err) => {
            //console.log(err);
            this.$root.$emit("loadOff");
          });
      });
    },
    editEmail(data) {
      this.editedEmail = true;
      this.editedEmailType = data;
      var keyUrl = "";
      if (this.editedEmailType === "liberacao") {
        keyUrl = "keys[]=welcomeCourseEmailDesign";
      } else {
        keyUrl = "keys[]=certificateEmailDesign";
      }

      this.$root.$emit("loadOn");
      serviceMeta
        .search(keyUrl)
        .then((resp) => {
          //console.log("meta email", resp);
          this.$refs.emailEditor.editor.loadDesign(
            JSON.parse(resp.welcomeCourseEmailDesign)
          );
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    designUpdated() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        //console.log('exportHtml', data);
      });
    },
    editorLoaded() {
      let email = this.template_selected;
      this.$refs.emailEditor.editor.loadDesign(email);
    },
    exportHtml() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        //console.log('exportHtml', data);
      });
    },
    addTemplateInitial() {
      this.template_selected = white;
    },
    getCourse() {
      let data = {
        id: this.cursoAtual,
      };
      serviceCourse
        .read(data)
        .then((resp) => {
          //console.log("get course", resp);
          this.nameCourse = resp.title;
          if (resp.status == "published") {
            this.myDataVariable = true;
          } else {
            this.myDataVariable = false;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getMail() {
      const params = this.$route.params.curso;

      if (params === null || params === undefined || params === "") {
        this.$router.push("/course");
      } else {
        this.cursoAtual = params;
        this.existParans = false;
        this.getCourse();
      }
    },
  },
  mounted() {
    this.getMail();
  },
};
</script>
<style lang="scss">
.createEmail {
  .btnsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .hide {
    display: none;
  }
  #bar {
    iframe {
      min-width: 1024px !important;
      min-height: 425px !important;
    }
    .unlayer-editor {
      min-height: 0px !important;
    }
  }
  .spacebtnEmail {
    margin-top: 20px;
    .btn-salvaremail {
      background: var(--maincolor);
      border-radius: 30px;
      height: 45px;
      width: 245px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #ffffff;
      border: none;
      margin-left: 20px;
    }
    .btn-cancelaremail {
      border-radius: 30px;
      height: 45px;
      width: 245px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      border: none;
      background: #f7f7f7;
      color: #81858e;
    }
  }
  .removeLink {
    text-decoration: none;
  }
  .spaceBtns {
    margin-bottom: 15px;
    .btnActionEmail {
      background: rgba(0, 35, 99, 0.1);
      border-radius: 30px;
      width: 344.97px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      height: 60px;
      letter-spacing: 0.2px;
      color: #002363;
      border: none;
    }
  }
  .table {
    margin-top: 40px;
    .table-sm th,
    .table-sm td {
      padding: 0.5rem !important;
    }
    th {
      border: none !important;
      font-family: Inter !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 14px !important;
      letter-spacing: 0.2px !important;
      color: #333 !important;
    }
    tr.row-table-body {
      line-height: 40px !important;
    }
    td {
      border-top: none !important;
      border-bottom: 1px solid #e5e5e5 !important;
    }
    .table thead th {
      border: none !important;
    }
    .text {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      letter-spacing: 0.2px;
      color: #81858e;
    }
    .spaceTimeBlock {
      width: 134px;
      height: 40px;
      left: 1032px;
      top: 248.29px;
      background: var(--maincolortrans);
      border-radius: 30px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: var(--maincolor);
      border: none;
    }
    .spaceTimeBlock2 {
      border: none;
      width: 134px;
      height: 40px;
      left: 1032px;
      top: 248.29px;
      background: rgba(0, 35, 99, 0.1);
      border-radius: 30px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #002363;
    }
    .textArrow {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      letter-spacing: 0.2px;
      color: #81858e;
      display: flex;
      img {
        padding-left: 20px;
      }
    }
  }
  .removeLink {
    text-decoration: none;
  }

  .disableLink {
    pointer-events: none;
  }
  .ql-editor {
    min-height: 100px !important;
  }
  .navigate {
    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      color: var(--fontcolor) !important;
      word-break: break-word;
    }
    .lineDiv {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      height: 0.5px;
      background: #ededf0;
    }
    .spaceBtn {
      padding: 4px;
      margin-top: 10px;
      .btnDesativar {
        background: rgba(0, 35, 99, 0.1);
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 30px;
        border: none;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #002363;
        height: 45px;
        width: 100%;
      }
    }
    .spaceNav {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      transform: translateY(0px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
      .text {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        color: #81858e;
      }
    }
    .spaceNav:hover {
      transform: translateY(2px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
    }
  }
  .editModuleClass {
    position: fixed;
    background: rgba(0, 0, 0, 0.24);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999999999999999999;
    .contentAdd {
      z-index: 99999999999999999999999999;
      position: absolute;
      padding: 30px;
      right: 0;
      top: 25vh;
      width: 303px;
      height: 295px;
      background: #ffffff;
      border: solid 1px var(--bordercolor);
      box-sizing: border-box;
      border-radius: 3px;
      .close {
        cursor: pointer;
      }
      .space {
        margin-top: 40px;
        margin-bottom: 40px;
        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 40px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #ededf0;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: #333333;
        }
        .btn-salvar {
          background: var(--maincolor);
          border-radius: 30px;
          border: none;
          width: 146px;
          font-family: Inter;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
      }
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--maincolor);
      }
    }
  }
  .hide {
    display: none !important;
  }
  .spaceWizzard {
    .viewAulas {
      .flexAula {
        display: flex;
        justify-content: space-between;
        .flex {
          display: flex;
          .action {
            position: relative;
            top: 8px;
          }
          .play {
            position: relative;
            top: 6px;
          }
        }
      }
      .textAula {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
        margin-left: 20px;
      }
      .timeAula {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
      }
      .actionAula {
        display: flex;
        justify-content: space-between;
        svg {
          position: relative;
          left: 24px;
          z-index: 2;
        }
        button.btn.dropdown-toggle.btn-secondary,
        button.btn.dropdown-toggle.btn-secondary:hover,
        button.btn.dropdown-toggle.btn-secondary:focus,
        button.btn.dropdown-toggle.btn-secondary:active,
        button.btn.dropdown-toggle.btn-secondary:focus-within,
        button.btn.dropdown-toggle.btn-secondary:focus-visible {
          background: rgba(255, 255, 255, 0) !important;
          border: rgba(0, 0, 0, 0) !important;
          box-shadow: none !important;
          z-index: 9;
          color: #303e3e00 !important;
        }
        a.dropdown-item {
          font-family: Inter !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          color: #333 !important;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
          color: #333 !important;
          text-decoration: none !important;
          background-color: #fff !important;
        }
      }
    }
    .blur1 {
      position: fixed;
      left: 1px;
      bottom: -60px;
    }
    .blur2 {
      position: fixed;
      right: 1px;
      top: 80px;
      transform: rotate(180deg);
    }
    .spaceInputsCreate {
      display: flex;
      justify-content: flex-end;
      position: relative;
      left: 5em;
      top: -2em;
      .btn-criar {
        background: var(--maincolor);
        border: 1px solid var(--maincolor);
        box-sizing: border-box;
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 30px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        width: 100%;
        height: 55px;
        margin-top: 40px;
        width: 250px;
      }
    }
    .containerWizzardInputs {
      margin-left: auto;
      margin-right: auto;
      width: 95%;
      margin-top: 50px;
      .paddingNewModule {
        padding-left: 80px;
        padding-right: 80px;
        padding-top: 10px;
      }
      .btn-newModule {
        background: rgba(129, 133, 142, 0.03);
        border: 2px dashed #81858e;
        box-sizing: border-box;
        border-radius: 30px;
        height: 55px;
        cursor: pointer;
        .text {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #81858e;
          display: flex;
          justify-content: center;
          margin-top: 17px;
        }
      }
      .removemb {
        margin-bottom: 0px !important;
      }
      .actionModule {
        display: flex;
        justify-content: space-between;
        .titleModule {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: var(--maincolor);
        }
        svg {
          position: relative;
          left: 24px;
          z-index: 2;
        }
        button.btn.dropdown-toggle.btn-secondary,
        button.btn.dropdown-toggle.btn-secondary:hover,
        button.btn.dropdown-toggle.btn-secondary:focus,
        button.btn.dropdown-toggle.btn-secondary:active,
        button.btn.dropdown-toggle.btn-secondary:focus-within,
        button.btn.dropdown-toggle.btn-secondary:focus-visible {
          background: rgba(255, 255, 255, 0) !important;
          border: rgba(0, 0, 0, 0) !important;
          box-shadow: none !important;
          z-index: 9;
          color: #303e3e00 !important;
        }
        a.dropdown-item {
          font-family: Inter !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          color: #333 !important;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
          color: #333 !important;
          text-decoration: none !important;
          background-color: #fff !important;
        }
      }
      .lineDiv {
        background: #ededf0;
        transform: matrix(1, 0, 0, -1, 0, 0);
        height: 0.5px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .spaceModuleCard {
        width: 100%;
        background: #ffffff;
        border: 0.5px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 30px;
        padding: 32px;
        margin-bottom: 20px;
      }
      .spaceInputs {
        margin-bottom: 30px;
        .textInfo {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          color: #81858e;
          margin-top: 5px;
        }
        .btn-criar {
          background: var(--maincolor);
          border: 1px solid var(--maincolor);
          box-sizing: border-box;
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          border-radius: 30px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #ffffff;
          width: 100%;
          height: 55px;
          margin-top: 40px;
        }
        .btn-openModal {
          background: var(--maincolortrans);
          border: 2px dashed var(--maincolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 55px;
          cursor: pointer;
          .text {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: var(--maincolor);
            display: flex;
            justify-content: center;
            margin-top: 17px;
          }
        }
        .gerenciarCat {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          letter-spacing: 0.2px;
          color: var(--maincolor);
          position: absolute;
          right: 9em;
          cursor: pointer;
        }
        select {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 55px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #6d767e;
          overflow: hidden !important;
          resize: none !important;
        }
        input,
        textarea {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 55px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        select:hover,
        textarea:hover,
        input:focus,
        select:focus,
        textarea:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
      }
    }
    .containerWizzard {
      text-align: center;
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        align-items: center;
        color: var(--fontcolor) !important;
      }
    }
  }
  .containerx {
    width: 98%;
  }
}
</style>
